<template>
  <div>
    <TheCatcherComponent>
      <SwapModal
        :datalayer-category="props.productCategory"
        :modal-name="props.modalName"
        :price="props.price"
        :product-title="props.model"
        @confirmation="$emit('confirmation')"
      />
    </TheCatcherComponent>
    <div
      v-if="shouldDisplaySwapBlock"
      class="border-static-default-low rounded-md box-border flex flex-col items-stretch justify-between border p-24"
      :class="{ 'sm:border-0 md:border-0': compact }"
    >
      <div class="mb-12 flex items-center justify-between">
        <div
          class="bg-action-default-hi text-onaction-default-hi rounded-full m-4 flex items-center p-8"
        >
          <IconSwap class="m-auto" />
        </div>

        <span class="body-1-bold ml-16 flex-1">
          <span>
            {{ i18n(translations.swapTitle) }}
          </span>
        </span>
        <RevTooltip
          content-class-names="m-24 max-w-[22.5rem] md:m-0"
          :modifiers="popoverModifiers"
          position="top-end"
        >
          <template #trigger="{ show }">
            <RevButtonRounded
              class="-mr-12 cursor-pointer"
              :disabled="false"
              :icon="IconInfo"
              variant="secondary"
              @click="show"
            />
          </template>
          {{ i18n(translations.swapTooltip) }}
        </RevTooltip>
      </div>
      <div
        class="flex flex-col md:flex-row md:items-start"
        :class="{
          'flex-col md:flex-col': compact || swapLayout === 'vertical',
        }"
      >
        <span
          class="body-2"
          :class="{
            'mb-7 md:mb-0': !compact,
            'mb-6 md:mb-6': compact || swapLayout === 'vertical',
          }"
        >
          <FormattedMessage
            data-test="swap-message"
            :definition="translations.swapSubtitle"
            :values="{ hasSwapOffer: hasOffer }"
          >
            <template #model>
              {{ model }}
            </template>
            <template #price>
              <strong>
                {{
                  i18n(translations.swapPrice, {
                    price: props.price,
                    isPriceForFree: isPriceForFree,
                  })
                }}
              </strong>
            </template>
            <template #itemToSwap>
              <strong>
                {{ swapOfferTitle }}
              </strong>
            </template>
          </FormattedMessage>
        </span>

        <RevButton
          class="ml-0 md:flex-1"
          :class="{ 'md:ml-7': hasButtonMargins }"
          data-qa="swap-cta"
          data-test="swap-cta"
          full-width="always"
          variant="secondary"
          @click.stop="handleSwap"
        >
          <span>
            {{ i18n(translations.getSwapEstimation, { hasOffer }) }}
          </span>
        </RevButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, ref } from 'vue'

import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButton } from '@ds/components/Button'
import { RevButtonRounded } from '@ds/components/ButtonRounded'
import { openModal } from '@ds/components/ModalBase'
import { RevTooltip } from '@ds/components/Tooltip'
import { IconInfo } from '@ds/icons/IconInfo'
import { IconSwap } from '@ds/icons/IconSwap'

import TheCatcherComponent from '~/scopes/buyback/components/TheCatcher/TheCatcherComponent.vue'
import type { ErrorData } from '~/scopes/buyback/components/TheCatcher/useCatcher'

import SwapModal from '../SwapModal/SwapModal.vue'
import { SWAP_INFO_MESSAGE } from '../SwapModal/constants'

import translations from './SwapBlock.translations'

defineEmits(['refresh-estimations', 'confirmation', 'continue'])

const { trackSwap } = useTracking()
const i18n = useI18n()

const props = withDefaults(
  defineProps<{
    model: string
    hasOffer: boolean
    swapModel: string
    trackingLabel: string
    modalName: string
    price: string
    compact?: boolean
    swapLayout?: string
    shouldDisplaySwapBlock?: boolean
    productCategory?: string
  }>(),
  {
    model: undefined,
    hasOffer: false,
    swapModel: '',
    swapRedirection: 'none',
    swapLayout: 'default',
    gradeId: undefined,
    productCategory: '',
    compact: false,
    trackingLabel: undefined,
    modalName: undefined,
    shouldDisplaySwapBlock: true,
    price: undefined,
  },
)

const errorData = inject<ErrorData>('errorData', ref({ message: '', step: '' }))
errorData.value = { message: SWAP_INFO_MESSAGE.SWAP_BLOCK, step: '' }

const isPriceForFree = computed(() => {
  const priceAmount = parseInt(props.price.split(' ')[0], 10)

  return priceAmount === 0
})

const swapOfferTitle = computed(() => {
  return props.hasOffer ? props.swapModel : ''
})

const hasButtonMargins = computed(() => {
  return !props.compact && props.swapLayout !== 'vertical'
})

const popoverModifiers = computed(() => {
  return [
    {
      name: 'offset',
      options: {
        offset: [0, 25],
      },
    },
    {
      name: 'flip',
      options: {
        fallbackPlacements: ['top'],
      },
    },
  ]
})

function handleSwap() {
  trackSwap({
    label: props.trackingLabel,
    category: props.productCategory,
  })

  openModal(props.modalName)
}
</script>
