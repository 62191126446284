<template>
  <RevDrawer
    :back-button-label="i18n(modalBuybackTranslations.backButtonLabel)"
    :close-button-label="i18n(modalBuybackTranslations.close)"
    data-test="modal-swap"
    :has-back-button="showBackButton"
    :name="props.modalName"
    :title="title"
    @back="handleBack"
    @close="handleClose"
    @open="handleOpen"
  >
    <template #body>
      <Intro
        v-if="displayIntro"
        :close-wording="closeWording"
        :price="props.price"
        :variant="intro"
        @close="handleDismissIntro"
        @next-step="getCategories"
      />

      <div v-if="displayLoader" class="mt-6 flex items-center justify-center">
        <RevLoadingScreen :text="loadingText" />
      </div>

      <Categories
        v-if="displayCategories"
        :categories="categories"
        class="mt-7"
        @next-step="getCategoryFunnel"
      />

      <div ref="container">
        <QuestionsForm
          v-if="displayFunnel"
          :active-step="activeQuestionsStep"
          class="mb-72"
          :funnel="funnel"
          :has-submit-button="false"
          :isLoadingOffer="isPostingAnswers"
          @next-question="getNextQuestion"
          @next-step="getNextStep"
          @show-button="handleShowFunnelButton"
          @submit-answers="handlePostAnswers"
        />
      </div>

      <NoOffer v-if="displayNoOffer" @sell-other="goToCategories" />

      <Offer v-if="displayOffer && offer" :offer="offer" />

      <span ref="bottom" class="scroll-mb-32" />

      <div class="bg-surface-default-mid absolute inset-x-24 bottom-24">
        <RevButton
          v-if="showFunnelButton"
          :form="QUESTIONS_FORM_ID"
          full-width="always"
          type="submit"
          variant="primary"
        >
          {{ funnelButtonLabel }}
        </RevButton>

        <div v-if="displayOffer && offer" class="flex space-x-6">
          <RevButton
            full-width="always"
            variant="secondary"
            @click="handleDeclineOffer"
          >
            {{ i18n(translations.declineOffer) }}
          </RevButton>

          <RevButton
            full-width="always"
            variant="primary"
            @click="handleAcceptOffer"
          >
            {{ i18n(translations.acceptOffer) }}
          </RevButton>
        </div>
        <div v-if="displayNoOffer" class="flex space-x-2">
          <RevButton
            full-width="always"
            variant="primary"
            @click="handleCloseModal"
          >
            {{ closeWording }}
          </RevButton>
        </div>
      </div>
    </template>
  </RevDrawer>
</template>

<script setup lang="ts">
import { useRoute } from '#imports'
import { computed, inject, nextTick, ref, watch } from 'vue'
import { type LocationQuery } from 'vue-router'

import {
  type GetOfferV1Response,
  getOfferV1,
} from '@backmarket/http-api/src/api-specs-buyback/customer/getOfferV1'
import {
  type FunnelStep,
  type GetQuestionsResponse,
  type Question,
  getQuestionsV3,
} from '@backmarket/http-api/src/api-specs-buyback/customer/getQuestionsV3'
import { HttpApiError } from '@backmarket/http-api/src/utils/HttpApiError'
import { useBuybackOffer } from '@backmarket/nuxt-layer-buyback/composables/buybackOffer/useBuybackOffer'
import modalBuybackTranslations from '@backmarket/nuxt-layer-buyback/utils/Modal.translations'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { hashObjects } from '@backmarket/utils/object/hashObjects'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButton } from '@ds/components/Button'
import { RevDrawer } from '@ds/components/Drawer'
import { RevLoadingScreen } from '@ds/components/LoadingScreen'
import { closeModal } from '@ds/components/ModalBase'

import QuestionsForm, {
  type NextQuestionPayload,
  type NextStepPayload,
  type ShowButtonPayload,
  type SubmitAnswersPayload,
} from '~/scopes/buyback/components/QuestionsForm/QuestionsForm.vue'
import { QUESTIONS_FORM_ID } from '~/scopes/buyback/components/QuestionsForm/constants'
import type { ErrorData } from '~/scopes/buyback/components/TheCatcher/useCatcher'
import Categories from '~/scopes/buyback/swap/components/Categories/Categories.vue'
import Intro from '~/scopes/buyback/swap/components/Intro/Intro.vue'
import NoOffer from '~/scopes/buyback/swap/components/NoOffer/NoOffer.vue'
import Offer from '~/scopes/buyback/swap/components/Offer/Offer.vue'
import { CHECKOUT } from '~/scopes/checkout/routes-names'
import { PRODUCT } from '~/scopes/product/route-names'

import translations from './SwapModal.translations'
import { STEPS, SWAP_INFO_MESSAGE } from './constants'

type Steps = (typeof STEPS)[keyof typeof STEPS]
const i18n = useI18n()
const logger = useLogger()
const route = useRoute()
const { trackSwapModal, trackClick } = useTracking()

const { saveBuybackOffer } = useBuybackOffer()

export interface SwapModalProps {
  modalName: string
  redirectOnCloseTarget?: string | null
  datalayerCategory: string
  price?: string
  intro?: 'atc' | 'plp'
  initialPayload?: LocationQuery
}

const emit = defineEmits(['confirmation', 'continue'])

const props = withDefaults(defineProps<SwapModalProps>(), {
  redirectOnCloseTarget: null,
  initialPayload: undefined,
  intro: undefined,
  price: '',
})

const getInitialStep = () => {
  if (!isEmpty(props.initialPayload)) {
    return STEPS.QUESTIONS
  }
  if (!props.intro) {
    return STEPS.CATEGORIES
  }

  return STEPS.INTRO
}

const step = ref<Steps>(getInitialStep())
const history = ref<
  Record<string, GetQuestionsResponse> | Record<string, null>
>({})
const funnel = ref<Array<FunnelStep>>([])
const categories = ref<Question | Record<string, never>>({})
const trackingEnabled = ref(true)
const showFunnelButton = ref(false)
const funnelButtonLabel = ref('')
const scrollToTop = ref(false)
const container = ref<HTMLElement | null>(null)
const bottom = ref<HTMLElement | null>(null)
const isLoadingCategories = ref(false)
const isLoadingInitialPayload = ref(false)
const isPostingAnswers = ref(false)
const isOpen = ref(false)
const form = ref<LocationQuery>({})
const offer = ref<GetOfferV1Response>()
const errorData = inject<ErrorData>('errorData', ref({ message: '', step: '' }))
errorData.value = { message: SWAP_INFO_MESSAGE.SWAP_MODAL, step }

const showBackButton = computed(() => {
  return step.value !== STEPS.INTRO
})

const activeQuestionsStep = computed(() => {
  const steps = funnel.value.map(
    ({ step: questionFunnelStep }) => questionFunnelStep,
  )

  return steps.find((questionFunnelStep) => questionFunnelStep?.active) || null
})

const titles = computed(() => {
  const titleTranslations =
    typeof activeQuestionsStep.value?.label === 'string'
      ? i18n(translations.title, {
          stepNumber:
            funnel.value.findIndex(
              ({ step: questionFunnelStep }) =>
                questionFunnelStep?.key === activeQuestionsStep?.value?.key,
            ) + 1,
          totalStep: funnel.value.length,
          label: activeQuestionsStep.value.label,
        })
      : ''

  return {
    [STEPS.INTRO]: i18n(translations.categoriesTitle),
    [STEPS.CATEGORIES]: i18n(translations.categoriesTitle),
    [STEPS.QUESTIONS]: titleTranslations,
    [STEPS.NO_OFFER]: i18n(translations.noOfferTitle),
    [STEPS.OFFER]: i18n(translations.offerTitle),
  }
})

const title = computed(() => {
  return titles.value[step.value]
})

const dismissTrackingLabel = computed(() => {
  if (route.name === PRODUCT.HOME && props.redirectOnCloseTarget === null) {
    return 'back_to_product'
  }

  return 'go_to_cart'
})

const closeWording = computed(() => {
  if (props.redirectOnCloseTarget !== null) {
    return i18n(translations.noToCart)
  }
  if (route.name === PRODUCT.HOME) {
    return i18n(translations.noFromProduct)
  }
  if (route.name === CHECKOUT.CART) {
    return i18n(translations.noFromCart)
  }

  return ''
})

const displayLoader = computed(() => {
  return (
    isLoadingCategories.value ||
    isPostingAnswers.value ||
    isLoadingInitialPayload.value
  )
})

const loadingText = computed(() => {
  return isPostingAnswers.value ? i18n(translations.loadingOfferText) : ''
})

const displayIntro = computed(() => {
  return step.value === STEPS.INTRO && !isLoadingCategories.value
})

const displayCategories = computed(() => {
  return step.value === STEPS.CATEGORIES && !isLoadingCategories.value
})

const displayFunnel = computed(() => {
  return (
    step.value === STEPS.QUESTIONS &&
    !isPostingAnswers.value &&
    !isLoadingCategories.value
  )
})

const displayNoOffer = computed(() => {
  return step.value === STEPS.NO_OFFER
})

const displayOffer = computed(() => {
  return step.value === STEPS.OFFER
})

function handleTracking({
  action,
  label,
}: {
  action: string
  label: string
}): void {
  trackSwapModal({
    category: props.datalayerCategory,
    ...form.value,
    action,
    label,
    // eslint-disable-next-line camelcase
    swap_estimation: i18n.price(offer.value?.listing.price || ''),
  })

  trackClick({
    value: {
      category: props.datalayerCategory,
      ...form.value,
      // eslint-disable-next-line camelcase
      swap_estimation: i18n.price(offer.value?.listing.price || ''),
    },
    name: label,
    zone: 'swap',
  })
}

async function getQuestions({
  formPayload = {},
  trackingKey = '',
}:
  | { formPayload: LocationQuery; trackingKey?: string }
  | Record<string, never> = {}): Promise<GetQuestionsResponse | null> {
  handleTracking({
    action: 'funnel > Step 2',
    label: trackingKey,
  })

  const id = hashObjects(formPayload)
  const cachedResponse = history.value[id]

  if (cachedResponse) {
    return cachedResponse
  }

  const questionsResponse = await $httpFetch(getQuestionsV3, {
    queryParams: { ...formPayload },
    pathParams: {
      kind: 'swap',
    },
  })

  if (isEmpty(questionsResponse)) {
    logger.error(SWAP_INFO_MESSAGE.EMPTY_QUESTIONS, {
      error: new Error('Empty response from questions API'),
      info: { ...formPayload },
    })
  }

  history.value[id] = questionsResponse

  return questionsResponse
}

async function getNextQuestion({
  formPayload = {},
  reset = [],
  trackingKey = '',
}: NextQuestionPayload): Promise<void> {
  try {
    form.value = {
      ...form.value,
      ...formPayload,
    }

    if (reset.length) {
      reset.forEach((key) => {
        delete form.value[key]
      })
    }

    const payload = await getQuestions({
      formPayload: form.value,
      trackingKey,
    })
    funnel.value = payload?.funnel || []
  } catch (err) {
    const error = err as Error
    logger.error(SWAP_INFO_MESSAGE.GET_QUESTIONS, { error })
  }
}

async function getNextStep({
  formPayload,
  shouldScrollToTop,
  trackingKey = '',
}: NextStepPayload) {
  scrollToTop.value = shouldScrollToTop

  await getNextQuestion({ formPayload, trackingKey })

  if (scrollToTop.value && container.value) {
    container.value.scrollIntoView(true)
    scrollToTop.value = false
  }
}

async function handleShowFunnelButton({
  label,
  visible,
  scrollBottom,
}: ShowButtonPayload) {
  funnelButtonLabel.value = label
  showFunnelButton.value = visible

  if (scrollBottom && bottom.value) {
    await nextTick()
    bottom.value.scrollIntoView({
      block: 'end',
      inline: 'nearest',
      behavior: 'smooth',
    })
  }
}

async function handlePostAnswers({
  trackingKey = '',
  formPayload,
}: SubmitAnswersPayload) {
  try {
    form.value = {
      ...form.value,
      ...formPayload,
    }
    isPostingAnswers.value = true
    showFunnelButton.value = false

    // Wait 800 before fetching the offer in order to
    // give customers the feeling that we are 'really' searching
    // (meanwhile a screen loader with additional information is shown)
    await new Promise((resolve) => {
      setTimeout(resolve, 800)
    })

    handleTracking({
      action: 'funnel > Step 2',
      label: trackingKey,
    })

    handleTracking({
      action: 'funnel > Step 2',
      label: 'see_estimate',
    })

    offer.value = await $httpFetch(getOfferV1, {
      queryParams: {
        ...form.value,
      },
      pathParams: {
        kind: 'swap',
      },
    })

    step.value = STEPS.OFFER
  } catch (error) {
    const httpError = error as HttpApiError

    if (httpError.status === 404) {
      step.value = STEPS.NO_OFFER
      handleTracking({
        action: 'funnel > Step 2',
        label: 'scarabee',
      })
    } else {
      logger.error(SWAP_INFO_MESSAGE.GET_OFFER, { httpError })
      showFunnelButton.value = true
    }
  } finally {
    isPostingAnswers.value = false
  }
}

function resetFunnel(): void {
  funnel.value = []
  form.value = {}
  categories.value = {}
  step.value = STEPS.INTRO
  trackingEnabled.value = true
  showFunnelButton.value = false
}

function handleClose(): void {
  isOpen.value = false
  // dismiss tracking cases, depends on modal name and if tracking is enabled
  if (trackingEnabled.value) {
    handleTracking({
      action: 'funnel',
      label: dismissTrackingLabel.value,
    })
  }

  if (props.redirectOnCloseTarget === CHECKOUT.CART) {
    emit('continue')
  }

  if (step.value === STEPS.OFFER) {
    emit('confirmation')
  }

  resetFunnel()
}

function handleCloseModal() {
  closeModal(props.modalName)
}

async function handleDismissIntro() {
  handleTracking({
    action: 'funnel > Step 1',
    label: dismissTrackingLabel.value,
  })

  // prevent generic trackers in closeModal
  trackingEnabled.value = false
  handleCloseModal()
}

async function getCategories(): Promise<void> {
  handleTracking({
    action: 'funnel > Step 1',
    label: 'buyback_estimate',
  })

  isLoadingCategories.value = true

  try {
    const payload = await getQuestions()

    const firstStepQuestion = payload?.funnel || []

    categories.value = firstStepQuestion[0].questions[0]
    step.value = STEPS.CATEGORIES
  } catch (err) {
    const error = err as Error
    logger.error(SWAP_INFO_MESSAGE.GET_CATEGORIES, { error })
  } finally {
    isLoadingCategories.value = false
  }
}

async function getCategoryFunnel(category: { category: string }) {
  try {
    form.value = {
      ...category,
    }
    const payload = await getQuestions({
      formPayload: form.value,
      trackingKey: 'category',
    })

    funnel.value = payload?.funnel || []
    step.value = STEPS.QUESTIONS
  } catch (err) {
    const error = err as Error
    logger.error(SWAP_INFO_MESSAGE.GET_QUESTIONS, { error })
  }
}

async function handleAcceptOffer() {
  try {
    handleTracking({
      action: 'funnel > Step 3',
      label: 'accept_offer',
    })

    if (offer.value) {
      await saveBuybackOffer(offer.value, form.value)
    }
  } catch (err) {
    const error = err as Error
    logger.error(SWAP_INFO_MESSAGE.ADD_SWAP, { error })
  }
  trackingEnabled.value = false
  handleCloseModal()
}

// TODO: can be integrated into getCategories
async function goToCategories() {
  if (isEmpty(categories.value)) {
    await getCategories()
  }
  step.value = STEPS.CATEGORIES
}

async function getFunnelStepFromHistory(): Promise<void> {
  const isFirstQuestionStep =
    funnel.value.findIndex(
      ({ step: questionFunnelStep }) => questionFunnelStep?.active,
    ) === 0

  if (isFirstQuestionStep) {
    await goToCategories()
    showFunnelButton.value = false

    return
  }

  try {
    const activeFunnelQuestionsStep = funnel.value.find(
      ({ step: questionFunnelStep }) => questionFunnelStep?.active,
    )
    const keysToDelete = activeFunnelQuestionsStep?.questions.map(
      (question) => question.key,
    )

    keysToDelete?.forEach((key) => {
      delete form.value[key]
    })

    form.value = {
      ...form.value,
      nextStep: 'false',
    }

    const payload = await getQuestions({ formPayload: form.value })
    funnel.value = payload?.funnel || []
  } catch (err) {
    const error = err as Error
    logger.error(SWAP_INFO_MESSAGE.GET_QUESTIONS, { error })
  }
}

async function handleBack(): Promise<void> {
  if (step.value === STEPS.CATEGORIES) {
    step.value = STEPS.INTRO
  }

  if (step.value === STEPS.QUESTIONS) {
    await getFunnelStepFromHistory()
  }

  if (step.value === STEPS.OFFER || step.value === STEPS.NO_OFFER) {
    step.value = STEPS.QUESTIONS

    showFunnelButton.value = true
  }
}

async function handleDeclineOffer() {
  handleTracking({
    action: 'funnel > Step 3',
    label: 'decline_offer',
  })

  // prevent generic trackers in closeModal
  trackingEnabled.value = false
  handleCloseModal()
}

async function handleOpen() {
  isOpen.value = true
  trackingEnabled.value = true
  handleTracking({
    action: 'modal',
    label: 'swap',
  })
}

watch(
  [() => props.initialPayload, () => props.intro, isOpen],
  async ([newInitialPayload, newIntro, newIsOpen]) => {
    if (newIsOpen) {
      step.value = getInitialStep()
      if (!isEmpty(newInitialPayload)) {
        isLoadingInitialPayload.value = true
        await getNextQuestion({ formPayload: newInitialPayload })
        isLoadingInitialPayload.value = false

        return
      }

      if (!newIntro) {
        await getCategories()
      }
    }
  },
  { immediate: true },
)

watch(
  [step, isOpen],
  ([newStep, newIsOpen]) => {
    if (newIsOpen) {
      logger.info(SWAP_INFO_MESSAGE.VIEW_SWAP_STEP, { step: newStep })
    }
  },
  { immediate: true },
)
</script>
